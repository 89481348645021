export default {
  AVAILABLE_LOCALES: ['en_GB', 'en_US', 'fr_CA', 'fr_FR', 'es_ES', 'zh_HK', 'zh_CN', 'zh_TW', 'pt_PT', 'de_DE'],
  DEFAULT_LOCALE: 'fr_FR',
  DEFAULT_YELDA_URL: '/5bc39dd9fef59a2d08f1cf29/yelda?locale=fr_FR',
  REGEX: {
    LOCALE: /[a-z]{2}_[A-Z]{2}/
  },
  WEBCHAT_ASSISTANT_URL: 'https://demo.yelda.ai',
  WEBCHAT_PATH: '/chat'
}
