import Vue from 'vue'
import VueI18n from 'vue-i18n'
import messages from './message'

Vue.use(VueI18n)

export default new VueI18n({
  fallbackLocale: 'en_US',
  locale: 'fr_FR',
  messages,
  silentFallbackWarn: true,
  silentTranslationWarn: process.env.NODE_ENV === 'production'
})
