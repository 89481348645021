import Vue from 'vue'
import Router from 'vue-router'
import App from '@/App.vue'
Vue.use(Router)

export default new Router({
  mode: 'history',
  routes: [
    {
      path: `/${import.meta.env.VITE_ORANGE_SPECIAL_ROUTE}/:campaign_slot`,
      name: `${import.meta.env.VITE_ORANGE_SPECIAL_ROUTE}`,
      component: App
    },
    {
      path: '/:assistantId/:assistantSlug',
      name: 'yelda',
      component: App
    },
    {
      path: '*',
      redirect: {
        name: 'yelda'
      }
    }
  ]
})
