<template>
  <div id="app" :key="$route.fullPath" :style="{ 'background-image': `url('${pageBackground}')` }" class="w-100 h-100">
    <header class="align-items-center">
      <img v-if="headerLogo" :src="headerLogo" width="230px" height="70px" class="d-inline-block" />
      <h3 class="d-inline-block m-0 align-middle ml-3">{{ platformSettings.headerTitle }}</h3>
    </header>
    <b-modal
      v-model="isModalVisible"
      :hide-footer="true"
      :no-close-on-backdrop="true"
      :no-close-on-esc="true"
      :title="$t('modal_title')"
    >
      <p class="text-left">{{ $t('modal_message') }}</p>
      <b-button variant="primary" @click="redirectToYeldaAssistant">{{ $t('modal_button_text') }}</b-button>
    </b-modal>
  </div>
</template>

<script>
import axios from 'axios'
import yeldaChat from 'yelda-webchat'
import 'yelda-webchat/dist/css/yeldaWebchatInjector.min.css'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import config from './config/default.js'
import configClient from './config/client.js'

export default {
  name: 'App',
  data() {
    return {
      availableLocales: config.AVAILABLE_LOCALES,
      baseApiUrl: import.meta.env.VITE_BASE_API_URL,
      defaultRedirectionUrl: config.DEFAULT_YELDA_URL,
      isModalVisible: false,
      platformSettings: {}
    }
  },
  computed: {
    headerLogo() {
      return this.platformSettings && this.platformSettings.headerLogo ? this.platformSettings.headerLogo.url : ''
    },
    locale() {
      return RegExp(config.REGEX.LOCALE).test(this.$route.query.locale) &&
        this.availableLocales.includes(this.$route.query.locale)
        ? this.$route.query.locale.trim()
        : config.DEFAULT_LOCALE
    },
    pageBackground() {
      return this.platformSettings && this.platformSettings.pageBackground
        ? this.platformSettings.pageBackground.url
        : ''
    }
  },
  watch: {
    '$route.fullPath': function () {
      this.initData()
    }
  },
  created() {
    this.initData()
  },
  methods: {
    getClientExtraParams() {
      if (!configClient[this.$route.name]) {
        return null
      }

      const slots = []
      configClient[this.$route.name].YPARAMS_SLOTS.forEach((element) => {
        if (this.$route.params[element]) {
          slots.push({ [element]: this.$route.params[element] })
        }
        if (this.$route.query[element]) {
          slots.push({ [element]: this.$route.query[element] })
        }
      })

      return {
        yparam: JSON.stringify({ slots }),
        ...configClient[this.$route.name]?.WEBCHAT_INIT_PARAMS
      }
    },
    initData() {
      // Get setting
      axios
        .get(`${this.baseApiUrl}/${this.$route.name || ''}`, {
          params: {
            ...(this.$route.params.assistantId && { assistantId: this.$route.params.assistantId.trim() }),
            ...(this.$route.params.assistantSlug && { assistantSlug: this.$route.params.assistantSlug.trim() }),
            locale: this.locale
          }
        })
        .then(async (response) => {
          // .slug, .name, ._id, .settings
          const assistantDetails = response.data

          // Cannot load the webchat without these data
          if (!assistantDetails || !assistantDetails._id || !assistantDetails.slug) {
            this.isModalVisible = true
            return
          }

          // Loading webchat
          await this.loadChat(assistantDetails._id, assistantDetails.slug, this.getClientExtraParams())

          // Set style of the webchat
          this.setChatStyle(assistantDetails)
        })
        .catch((err) => {
          console.error(' error', err)
          this.isModalVisible = true
        })
    },
    async loadChat(assistantId, assistantSlug, getClientExtraParam = null) {
      const chatData = {
        assistantSlug: assistantSlug,
        assistantId: assistantId,
        assistantUrl: config.WEBCHAT_ASSISTANT_URL,
        chatPath: config.WEBCHAT_PATH,
        locale: this.locale,
        shouldBeOpened: true,
        ...(getClientExtraParam && { ...getClientExtraParam })
      }

      if (yeldaChat && assistantId) {
        await yeldaChat.setupChat(chatData)
      } else {
        console.info("can't init webchat")
      }
    },
    redirectToYeldaAssistant() {
      this.isModalVisible = false
      this.$router.push({ path: this.defaultRedirectionUrl })
    },
    setChatStyle(assistantDetails) {
      const assistantName = assistantDetails.name
      let pageTitle = ''

      const settingWithPlatform = assistantDetails.settings.find((setting) => setting.platformSettings)
      // set the webchat offset
      if (settingWithPlatform) {
        this.platformSettings = settingWithPlatform.platformSettings
        this.setOffset(this.platformSettings)
        pageTitle = this.platformSettings.headerTitle || ''
      }

      const settingWithIdentity = assistantDetails.settings.find((setting) => setting.identity)
      const identitySettings = settingWithIdentity && settingWithIdentity.identity

      // if no identity settings, only set page title
      if (!identitySettings) {
        this.setTitle(assistantName, pageTitle)
        return
      }

      // if identity settings available, set favicon and page title
      if (identitySettings.image && identitySettings.image.url) {
        this.setFavicon(identitySettings.image.url)
      }
      this.setTitle(identitySettings['assistant-name'] || assistantName, pageTitle)
    },
    setFavicon(imageUrl) {
      const link = document.createElement('link')
      const oldLink = document.querySelectorAll('[rel="shortcut icon"]')[0]
      link.id = 'dynamic-favicon'
      link.rel = 'shortcut icon'

      link.href = imageUrl
      if (oldLink) {
        document.head.removeChild(oldLink)
      }
      document.head.appendChild(link)
    },
    setOffset(publicSettings) {
      if (!document.getElementById('yelda_container')) {
        console.warning('no yelda_container')
        return
      }

      if (publicSettings.isWebChatCentered || !Object.hasOwn(publicSettings, 'horizontalOffset')) {
        document.getElementById('yelda_container').classList.add('centeredYeldaContainer')
      } else {
        document.getElementById('yelda_container').style.left = `${publicSettings.horizontalOffset}px`
      }
    },
    setTitle(assistantName = '', title = '') {
      document.title = `${assistantName} - ${title}`
    }
  }
}
</script>

<style>
@import url('https://fonts.googleapis.com/css?family=Cabin:300i,400,400i,500,500i,600,600i,700,700i');

html,
body {
  height: 100%;
  width: 100%;
}

@media screen and (min-width: 768px) {
  body > .centeredYeldaContainer {
    left: 50%;
    transform: translateX(-25%);
  }
}

#app {
  font-family: 'Cabin', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  background-repeat: no-repeat;
  background-size: cover;
}

#app > header {
  padding: 10px 0;
  background-color: rgba(255, 255, 255, 0.9);
}
</style>
